@import '../../../../Scss//custom';

.address-details-container {
  position: relative;
  @include customFlex(space-between, flex-start);
  flex-direction: column;
  @include md {
    flex-direction: row;
  }
  .swap-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 100px;
    width: 100%;
    @include md {
      position: relative;
      height: auto;
      width: auto;
    }
    .swap-icon {
      width: 17%;
      left: 43%;
      top: 0;
      cursor: pointer;
      @include md {
        position: absolute;
        height: 52.5px;
        width: 52.5px;
        left: -26px;
        top: 285px;
      }
    }
  }
}
