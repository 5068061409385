@import '../../../../Scss/custom';

.parcel-details-container {
  @include md {
    // padding: 0 1.5rem;
  }
  .select-date-time-wrapper {
    @include commonContainer;
    .date-picker-wrapper {
      flex: 1 1 auto;
      padding: 1rem 1.25rem;
      margin-bottom: 1rem !important;
      .align-wrapper {
        width: 100%;
        @include md {
          // padding: 0rem 1rem;
        }
        label {
          width: fit-content;
          margin-bottom: 0.5rem;
          font-size: 0.875rem;
          font-weight: 500;
          display: block;
        }

        .parcel-date-picker-input {
          width: 100%;
          @include xl {
            width: 275px;
          }
          @include vnpPrimaryInput;

          .ant-picker-suffix {
            opacity: 0;
          }
        }
      }
    }
  }

  .packages-details-wrapper {
    margin-top: 1rem;
    @include commonContainer;
    @include md {
      margin-top: 2rem;
    }

    .add-package-btn-wrapper {
      @include customFlex(space-between, flex-start);
      @include xl {
        @include customFlex(space-between, center);
      }
      flex-direction: column-reverse;
      padding: 1rem 0.5rem;
      gap: 1rem;
      @include md {
        padding: 1rem 1.25rem;
      }
      @include xl {
        flex-direction: row !important;
      }
      span {
        color: black;
        font-size: 15px;
        font-weight: 600 !important;
        @media screen and (max-width: 370px) {
          align-self: flex-start;
          // margin-top: 2rem;
        }
      }
      .package-add-btn {
        @include vnpPrimaryBtn;
        @media screen and (max-width: 370px) {
          align-self: flex-end;
          margin-bottom: 1rem;
        }
        border-radius: 2px !important;
        float: right;
        white-space: nowrap;
        .btn-outline {
          border: 2px solid #fff;
          border-radius: 5px;
          padding: 0px 3px;
          margin-left: 6px;
          span {
            color: white;
            font-size: 11px;
            margin-left: -1px;
          }
        }
      }
      .pkg-btn-wrapper {
        display: flex;
        gap: 0.7rem;
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        @include sm {
          flex-direction: row;
        }
        @include xl {
          width: fit-content;
        }
        justify-content: flex-end;
      }
    }

    .all-pkg-wrapper {
      display: flex;
      flex-direction: column-reverse;
    }

    .package-content-wrapper {
      padding: 1rem 0.25rem;
      display: flex;
      background: linear-gradient(
        269.2deg,
        rgba(97, 192, 231, 0.11) 100.6%,
        rgba(0, 168, 237, 0) 126.77%
      );
      @include md {
        padding: 1rem 1.25rem;
      }
      .package-form-wrapper {
        width: 100%;
        .package-form {
          .top-form {
            @include customFlex(flex-start, flex-start);
            gap: 0.5rem;
            flex-direction: column;
            flex-wrap: wrap;
            @include md {
              flex-direction: row;
            }
            .ant-form-item {
              margin-bottom: 7px;
            }
            @include lg {
              flex-wrap: nowrap;
            }
          }
          .bottom-form {
            @include customFlex(flex-start, flex-start);
            gap: 0.5rem;
            padding-top: 1rem;
            flex-direction: column;
            .ant-form-item {
              width: 100%;
              margin-bottom: 7px;
            }
            @include md {
              padding-top: 7px;
              flex-direction: row;
            }
          }
          .package-select {
            width: 100%;
            @include lg {
              width: 70%;
            }
            @include xl {
              width: 90%;
            }
            .ant-select-arrow {
              font-size: 1rem;
              color: #6c757d;
            }
            .ant-select-selector {
              border: 1px solid #646464;
              border-radius: 8px;
              height: 39px;
              .ant-select-selection-search-input {
                height: 39px;
              }
              .ant-select-selection-item {
                margin-top: 2px;
              }
            }
            // @include vnpPrimaryInput;
          }
          .combo-weight {
            // width: 60%;
            width: 100%;
            @include xl {
              width: 25%;
            }
            @include lg {
              width: 50%;
            }
          }
          .counter-input {
            width: 100%;
            @include lg {
              width: 55%;
            }
            @include xl {
              width: 45%;
            }
            @include xxl {
              width: 35%;
            }
            .ant-input-group {
              border: 1px solid #646464;
              border-radius: 4px;
            }
            .ant-input-group-addon {
              padding: 0;
              button {
                border: none;
                height: 35px;
                width: 40px;
                background-color: white;
              }
            }
            .ant-input {
              height: 38px;
              border-left: 1px solid #646464;
              border-right: 1px solid #646464;
              text-align: center;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
          .package-input {
            @include vnpPrimaryInput;
            padding: 0 !important;

            .ant-input-group .ant-input{
              border: none;
              border-right: 1px solid #d9d9d9;
              padding: 8px 11px !important;
            }
             .ant-input-group-addon {
              border: none;
            }
          }
          .delete-package {
            // margin-bottom: 12px;
            @include md {
              margin-bottom: 7px;
            }
            font-size: 23px;
            cursor: pointer;
            width: 41px;
            place-items: center;
            background-color: transparent;
            display: grid;
            height: 39px;
            border: 1px solid #5eb4d8;
            border-radius: 5px;
            padding: 4px;
            color: rgb(145, 145, 145);
          }
        }
      }

      .package-img-wrapper {
        display: none;
        padding: 0 1rem;
        img {
          margin-top: -38px;
          width: 155px;
          @include lg {
            width: 200px;
          }
        }
        @include md {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.package {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0;
  color: #495057;
  background: #0000;
  transition: box-shadow 0.2s;
  border-radius: 0;
  font-weight: 400;
  font-size: 1rem;
}
// .ant-select-item-option-content {
//   color: #495057;
//   cursor: pointer;
//   font-weight: 400;
//   white-space: nowrap;
//   font-size: 1rem;
// }

.datepicker-popup {
  width: 80%;
  --arrow-y: -2px !important;
  top: 371px !important;
  @include sm {
    width: auto;
  }
  .ant-picker-panel-container{
    overflow: scroll;
  }
  
  .ant-picker-time-panel-column::after {
    display: none !important;
  }
  
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: #1677ff;
    color: white;
    &:hover {
      color: black;
    }
  }

  .ant-picker-time-panel-column {
    &::-webkit-scrollbar-thumb {
      width: 0px;
      height: 0px;
      background-color: rgba(209, 10, 10, 0);
    }
    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
    }
  }
}
