/*// Theme Colors */

$blobstationWhite: #ffffff;
$blobstationBlack: #000000;

$vnpPrimary: #146c91;
$vnpPrimaryLawOpacity: #146c91;
$vnpCardBackground: linear-gradient(206.48deg,rgba(154,205,226,.11) 16.62%,rgba(154,205,226,0) 132.88%);
$vnpSecondary: #9FC960;
$gredientBtn: linear-gradient(269.35deg, rgba(168, 206, 111, 0.97) -45.86%, #0876A4 121.72%) !important; 

/* Font family */
$font-family: -apple-system,BlinkMacSystemFont,San Francisco,Segoe UI,Roboto,Helvetica Neue,sans-serif;

/* Font weight */
$font-light: 300;
$font-regular: 400;
$font-normal: 500;
$font-semi-bold: 600;
$font-bold: 700;
$extra-bold: 800;

/* Font Size */
$fontsize-9: 9px;

/* padding */
$padding-zero: 0;

/* margin */
$margin-zero: 0;

/*dropdown */
$dropdown-menu-text: capitalize;

/* Button Theming */
$btn-background-color: $vnpPrimary;

/*Input text box */
$input-border-regular: 1px solid #b1b1b1 !important;

/* Screen size and media Queries*/
$screen-xsm-min: 320px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xl-sm-min: 1400px;
$screen-xxl-min: 1824px;
$screen-xxxl-min: 2300px;

/* header */
$main-header-bg: #f7f7f7;
$main-height-min: 74px;
$header-logo: 118px;

/* footer */
$footer-bg: #2c2c2c !important;

/* Mixin  */
/*--------------------Media query-----------------------*/
@mixin xsm {
    @media (min-width: #{$screen-xsm-min}) {
        @content;
    }
}

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}
@mixin sm-xl {
    @media (min-width: #{$screen-xl-sm-min}) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
}

@mixin xxxl {
    @media (min-width: #{$screen-xxxl-min}) {
        @content;
    }
}

// custom scrollbar
