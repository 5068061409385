@import '../../../../../Scss//custom';

.payment-btn-wrapper{
    @include customFlex(center, center);
    margin-top: 2rem;
    .payment-btn{
        @include vnpSecondryGreenBtn;
        padding: 12px 5rem;
        font-size: 1.2rem;
        &:hover{
            border: none;
        }
    }

}
.error-fallback{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;

    h3{
      margin: 1rem 0;
      color: red;
    }
}
#payment-message{
    margin: 1rem 0;
    text-align: center;
    color: red;
}