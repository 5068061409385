@import '../../../../Scss/custom';

.package-content-wrapper {
  padding: 1rem 0.25rem;
  display: flex;
  background: linear-gradient(
    269.2deg,
    rgba(97, 192, 231, 0.11) 100.6%,
    rgba(0, 168, 237, 0) 126.77%
  );
  @include md {
    padding: 1rem 1.25rem;
  }
  .package-form-wrapper {
    width: 100%;
    .package-form {
      .error-wrapper {
        color: red;
      }
      .ant-form-item .ant-form-item-explain-error {
        color: red !important;
        font-size: 12px;
      }
      .top-form {
        position: relative;
        @include customFlex(flex-start, flex-start);
        gap: 0.5rem;
        flex-direction: column;
        flex-wrap: wrap;
        @include md {
          flex-direction: row;
        }

        .package-label-wrapper{
          @include customFlex(center, center);
          border-radius: 4px;
          width: 100%;
          height: 39px;
          color: black;
          font-size: 1rem;
          font-weight: 600;
          background-color: #b3d0dd33;
          border: 1px solid black;
        }

        .ant-form-item {
          margin-bottom: 7px;
          width: 100%;
        }
        @include lg {
          flex-wrap: nowrap;
        }
      }
      .bottom-form {
        @include customFlex(flex-start, flex-start);
        gap: 0.5rem;
        padding-top: 1rem;
        flex-direction: column;
        .ant-form-item {
          width: 100%;
          margin-bottom: 7px;
        }
        @include md {
          padding-top: 7px;
          flex-direction: row;
        }
      }
      .package-select {
        width: 100%;
        .ant-select-single{
          height: auto;
        }
        @include lg {
          width: 70%;
        }
        @include xl {
          width: 90%;
        }
        .ant-select-arrow {
          font-size: 1rem;
          color: #6c757d;
        }
        .ant-select-selector {
          border: 1px solid #646464;
          border-radius: 8px;
          height: 39px;
          .ant-select-selection-search-input {
            height: 39px;
          }
          .ant-select-selection-item {
            margin-top: 2px;
          }
        }
        // @include vnpPrimaryInput;
      }
      .combo-weight {
        // width: 60%;
        .ant-form-item-label
          > label.ant-form-item-required:not(
            .ant-form-item-required-mark-optional
          )::before,
        .ant-form-item-label
          > label.ant-form-item-required:not(
            .ant-form-item-required-mark-optional
          )::after {
          display: none;
        }
        .ant-row {
          gap: 5px;
          display: flex;
          flex-direction: row-reverse;
        }
        width: 100%;
        @include xl {
          width: 25%;
        }
        @include lg {
          width: 50%;
        }
      }
      .counter-input {
        width: 100%;
        @include lg {
          width: 55%;
        }
        @include xl {
          width: 45%;
        }
        .ant-input-group {
          border: 1px solid #646464;
          border-radius: 4px;
        }
        .ant-input-group-addon {
          padding: 0;
          width: 35%;
          @include lg {
            width: auto;
          }
          border: none;
          button {
            border: none;
            width: 100% !important;
            height: 35px;
            background-color: white;
            @include lg {
              width: 40px !important;
            }
          }
        }
        .ant-input {
          height: 38px;
          border-left: 1px solid #646464;
          border-right: 1px solid #646464;
          text-align: center;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
      .package-input {
        @include vnpPrimaryInput;
      }
      .delete-package {
        // margin-bottom: 12px;
        @include md {
          margin-bottom: 7px;
        }
        font-size: 23px;
        cursor: pointer;
        width: 41px;
        place-items: center;
        background-color: #e4181ed1 !important;
        display: grid;
        height: 39px;
        border: none !important;
        border-radius: 5px;
        padding: 4px;
        color: white !important;
      }
    }
  }

  .package-img-wrapper {
    display: none;
    padding: 0 1rem;
    img {
      margin-top: -38px;
      width: 155px;
      @include lg {
        width: 200px;
      }
    }
    @include md {
      display: flex;
      align-items: center;
    }
  }
}

// @include sm{
  // .ant-form-item .ant-form-item-label{
  //   flex: ''; 
  // }
  // .ant-form-horizontal .ant-form-item-control {
  //   flex: '' !important; 
  //   @include sm{
  //     flex: '' !important; 
  //   }
  // }

