@import '../../../../Scss//custom';


@mixin quoteTxt {
  p {
    color: $vnpPrimary;
    font-size: 22px;
    margin: 0;
    text-align: center;
    span {
      color: $vnpSecondary;
    }
  }
}
.shipment-container {
  @include commonContainer;

  .shipment-list-container {
    margin-top: 1rem;
    overflow-x: scroll;
    @include md {
      padding: 1rem 1.25rem;
    }

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 10px;
      thead {
        background: #ddf5ff;
        box-shadow: 0 4px 4px #c9c9c924;
        tr {
          th {
            font-size: 14px;
            font-weight: 700;
            text-transform: none;
            letter-spacing: 0.04em;
            line-height: 1.6;
            color: #000;
            text-align: start;
            &:first-child {
              padding: 0.5rem 1.25rem;
            }
          }
        }
      }
      tbody {
        .quote-row{
          background: none !important;
        }
        .quote-container {
          .thanks-container{
            margin: 150px 0;
            font-weight: 700;
            @include quoteTxt();
            @include customFlex(center, center);
            flex-direction: column;
            p:first-child{
              margin-bottom: 10px;
              font-size: 2rem;
            }
          }
          .quote-txt-wrapper {
            text-align: center;
            font-weight: 700;
            margin: 150px 0;
            @include customFlex(center, center);
            flex-direction: column;
            @include quoteTxt();
            h4 {
              color: grey;
              font-size: 16px;
              margin: 0;
            }
            .get-quote-btn {
              background: #9fc960;
              font-weight: 600;
              border-radius: 4px;
              box-shadow: 1px 1px 10px #d3d3d3;
              box-sizing: border-box;
              padding: 7px 15px;
              margin: 15px;
              color: #fff;
              font-size: 22px;
              border: none;
              height: auto;
              width: auto;
              transition: all 0.3s ease-out;
            }
            .best-rates-info{
              padding: 1rem;
              font-weight: 600;
              text-align: center;
              color: $vnpPrimary;
              font-size: 18px;
              span{
                color: $vnpSecondary;
              }
            }
          }
        }
      }
    }

    footer {
      hr {
        opacity: 0.16;
        margin: 2rem 0;
      }
      .re-cal-price-btn {
        @include vnpStrokesBtn;
        float: right;
        cursor: pointer;
      }
    }
  }
}
