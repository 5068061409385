@import './shared.scss';

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin customFlex($content, $item) {
  display: flex;
  justify-content: $content;
  align-items: $item;
}

@mixin vnpVipBtn {
  height: 100%;
  width: 200px;
  border: none;
  color: #fff;
  padding: 12px;
  font-size: 14px;
  background-color: $vnpPrimary;
  border-right: 10px solid $vnpSecondary;
  @include flexCenter;
  flex-direction: column;
}

@mixin vnpPrimaryBtn {
  background-color: #0876a4;
  color: white;
  border: none;
  padding: 0.7rem 1rem;
  font-size: 0.874rem;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s all;
}

@mixin vnpGredientButton {
  font-weight: 400;
  padding: 12px;
  border: 1px solid white;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 6px;
  width: 100%;
  background: $gredientBtn;
  color: white;
  cursor: pointer;
}

@mixin vnpPrimaryInput {
  background-color: #fff;
  background-clip: padding-box;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 4px;
  border: 1px solid #646464 !important;

  &:focus {
    background-color: #fff;
    border-color: #90b5e2;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #206bc440;
  }
}

@mixin vnpSecondryGreenBtn {
  color: #fff;
  background: #9fc960 !important;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 11px 2rem;
  transition: 0.4s all;

  &:hover {
    border: 1px solid black;
  }
}

@mixin vnpToggleSwitch {
  margin-bottom: 0;
   .ant-switch-handle::before {
    background-color: $vnpPrimary;
  }
  .ant-switch.ant-switch-small {
    height: 18px;
    min-width: 32px;
  }
  .ant-switch {
    background-color: transparent;
    border: 1px solid #e2e2e2;
    &:hover {
      background-color: #b6bfc8;
    }
  }
  .ant-switch.ant-switch-checked {
    background-color: $vnpPrimary !important;

    & > .ant-switch-handle::before {
      background-color: white;
    }
  }
}
@mixin maskedInput {
  padding: 0.7rem 0.75rem !important;
  font-size: 0.875rem;
  border-radius: 4px;
  border: 1px solid #646464 !important;
  color: #000000e0;
  opacity: 1;

  &::placeholder {
    opacity: 0.5;
  }
  &:disabled {
    color: #626976;
    width: -webkit-fill-available;
    background-color: #fafbfc !important;
  }
}

@mixin invoiceViewBlock($color) {
  @include customFlex(center, center);
  background-color: lightgray;
  border: 2px solid black;
  font-size: 14px;
  height: 27px;
  text-align: center;
  color: $color;

  @media print {
    -webkit-print-color-adjust: exact;
    background-color: lightgrey;
    border: 1px solid black;
  }
}

@mixin orderInfoBtns {
  background: #c9effe !important;
  color: #0876a4 !important;
  border-radius: 4px;
  border: 1px solid #6269762e;
  padding: 0.5375rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;

  &:hover {
    border-color: #4299e1;
  }
}

@mixin vnpStrokesBtn {
  color: #0876a4 !important;
  border: 1px solid #0876a4 !important;
  padding: 0.4375rem 1rem;
  font-size: 0.875rem;
  border-radius: 4px;
  background: none;
  cursor: pointer;
}

@mixin commonContainer {
  width: 100%;
  // border: 1px solid #9dc7d9;
  background-color: #fff;
  // border-radius: 14px;
  .label-wrapper {
    border-bottom: 1px solid #62697629;
    span {
      padding: 14px !important;
      // border-top-left-radius: 15px;
      width: 100% !important;
      background: #0876a4 !important;
      @include vnpVipBtn;
      @include md {
        width: fit-content !important;
        border-top-left-radius: 15px;
      }
    }
  }
  @include md {
    border-radius: 14px;
    border: 1px solid #9dc7d9;
  }
}

@mixin signAndAttachmentStyle {
  margin: 10px 0px;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  margin-right: 0px;

  .img-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    justify-content: flex-start;
    @include md {
      justify-content: flex-end;
    }
    .img {
      position: relative;

      span {
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }

  .signature-style {
    height: 90px;
    width: 260px;

    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }

  .attachment-style {
    height: 90px;
    width: 100px;
  }

  .not-found {
    height: 70px;
    box-shadow: 1px 1px 10px #0e485e33;
    width: 100%;
    text-align: center;
    align-items: center;
    color: black;
    background-color: white;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 400px) {
      width: 286px;
    }
  }
}

.ant-input-disabled,
:where(.css-dev-only-do-not-override-txh9fw).ant-input[disabled] :hover {
  cursor: default !important;
}
