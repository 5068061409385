@import '../../../../Scss//custom';
.shipment-table{

  tbody {
    tr {
      background: #f8f8f8;
      .price {
        font-size: medium;
      }
      th {
        text-align: start;
        border-bottom: 1px solid #00000015;
        &:first-child {
          padding: 0.7rem 1.25rem;
        }
        &:last-child {
          padding-right: 1.25rem;
          .active {
            @include vnpStrokesBtn;
            background-color: $vnpPrimary;
            color: white !important;
            border-radius: 20px;
            padding: 8px 20px;
          }
          button {
            @include vnpStrokesBtn;
            border-radius: 20px;
            padding: 8px 20px;
            background-color: #fff;
            cursor: pointer;
  
            &:focus {
              box-shadow: 0 0 0 0.25rem #206bc440;
            }
          }
        }
      }
    }
  }
}

.dynamic-toggles {
  background: #f8f8f8;
  border-bottom: 1px solid #00000015;
  .toggles-wrapper {
    .pm-form{
      display: grid;
      grid-template-columns: repeat(3,1fr);
      padding: 1.5rem 1.25rem;
      gap: 5px;
    }

    .toggle {
        display: flex;
        align-items: center;
        .ant-form-item{
          margin: 0;
        }
      .price-switch {
        min-width: 33px;
        height: 17px;
        margin: 0 0.6rem;
        .ant-switch-handle {
          height: 14px;
          width: 14px;
        }
      }
      .ant-switch-checked{
        background-color: #206bc4;
         .ant-switch-handle {
          inset-inline-start: calc(100% - 17px) !important;
        }
      }
      label {
        font-weight: 700;
        display: block;
        user-select: none;
      }
      .disabled{
        opacity: .6;
      }
    }
  }
}
