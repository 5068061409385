@import '../../../Scss/custom';

@font-face {
  font-family: 'Circular Std Bold';
  src: url('../../../Resource/fonts/Circular Std Bold.ttf');
}

@mixin vnpResponsiveSteper {
  .ant-steps-item {
    // width: 80px;
    width: 0px;
    @media (max-width: 400px) {
      width: 31% !important;
    }
    @media (max-width: 350px) {
      width: 33% !important;
    }
    @include sm {
      width: 17px;
    }
  }
}

.order-entry-container {
  width: 100%;
  @include customFlex(center, flex-start);

  .order-steper-wrapper {
    width: 100%;
    @include md {
      padding: 1rem 1.25rem;
      background: $vnpCardBackground;
      width: 90%;
    }
    @include lg {
      width: 95%;
    }
    @include xl {
      width: 75%;
    }
    @include xxl {
      width: 70%;
    }
    padding: 0rem 0rem;
    border: 1px solid #62697629;
    border-radius: 4px;
    .order-steper-header {
      height: 110px;
      @include sm {
        height: 88px;
      }
      background: white;
      border: 1px solid #f3f2f2;
      box-shadow: 0 4px 34px #dddddd40;
      border-radius: 10px;
      padding: 0.7rem 4rem;

      .order-steps {
        height: -webkit-fill-available;
        margin-left: -90px;

        @include sm {
          margin-left: -70px !important;
        }
        @media (max-width: 400px) {
          margin-left: -116px !important;
        }
        @media (max-width: 370px) {
          margin-left: -117px !important;
        }
        .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
          height: 42px;
          width: 42px;
        }

        @include vnpResponsiveSteper;

        .ant-steps-item-tail::after {
          height: 2px !important;
          width: 100%;
          margin-top: 19px;
        }

        .ant-steps-item-wait
          .ant-steps-item-icon
          > .ant-steps-icon
          .ant-steps-icon-dot {
          height: 42px !important;
          width: 42px !important;
          @include customFlex(center, center);
          background-image: url('../../../Resource/Order/entry/AddressDetails/deactiveStep.svg') !important;
          background-size: cover !important;
          color: #1cadea;
        }

        .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
          height: 42px !important;
          width: 42px !important;
          @include customFlex(center, center);
          background-image: url('../../../Resource/Order/entry/AddressDetails/activeStep.svg') !important;
          background-size: cover !important;
        }

        .ant-steps-item-finish
          > .ant-steps-item-container
          > .ant-steps-item-tail::after {
          background-color: #1cadea;
        }
        .ant-steps-item-content {
          margin: 38px 0px 0px 20px;
          &:first-child {
            margin-top: 35px;
          }
        }
        .ant-steps-item-title {
          font-size: 15px;
          width: 50%;
          @include sm {
            width: auto;
          }
        }

        .ant-steps-item-process
          > .ant-steps-item-container
          > .ant-steps-item-content
          > .ant-steps-item-title {
          margin-top: -2px;
          font-weight: 600;
        }
      }
    }
    .applyPadding {
      padding: 0 10px;
    }
    .steper-content-container {
      .steper-heading {
        font-weight: 700;
        font-size: 17px;
        color: #1e293b;
        margin: 1rem;
        span {
          padding: 4px 11px 5px 10px;
          margin-right: 5px;
          background: #0876a4;
          border-radius: 100px;
          color: #fff;
        }
        .order-placed-txt {
          display: block;
          color: $vnpSecondary;
          width: 100%;
          text-align: center;
        }
      }
      .steper-content-wrapper {
        @include md {
          padding: 0.25rem;
        }
      }
    }

    .best-rates-info {
      padding: 1rem;
      font-weight: 600;
      text-align: center;
      color: $vnpPrimary;
      span {
        color: $vnpSecondary;
      }
    }
    .place-new-order-btn {
      @include vnpPrimaryBtn;
      padding: 0.6rem 1rem;
      margin-left: 1rem;
      margin-bottom: 1rem;
      
      @include md {
        margin-top: 13px;
      }
    }
    .steper-btn-container {
      gap: 1rem;
      padding: 1rem 10px !important;
      @include md {
        padding: 1rem 4px !important;
      }
      @include customFlex(flex-end, center);
      .save-draft-btn {
        @include vnpPrimaryBtn;
        padding: 0.6rem 1rem;
      }
      .hide {
        display: none;
      }
      .steper-next-btn {
        @include vnpSecondryGreenBtn;
        &:hover{
          border: none
        }
      }
      .steper-submit-btn {
        @include vnpStrokesBtn;
      }
      .steper-back-btn {
        @include vnpPrimaryBtn;
        padding: 0.6rem 1rem;
      }
    }
  }
}

.email-modal {
  .email-modal-content {
    background-color: #f8fdff;
    border-radius: 16px;
    border: 1px solid #caefff;
  }
  .email-modal-body {
    font-family: "Circular Std Bold";
    header {
      border-bottom: 1px solid #e3f7ff;
      display: flex;
      padding-right: 2rem;
      .text-wrapper {
        h2 {
          font-size: 22px;
          width: 90%;
          color: #146383;
          font-weight: 600;
          line-height: 25px;
          margin: 0;
        }
        span {
          color: #1e7a91;
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin: 8px 0px 15px 0px;
        }
      }
    }
  }
  .note {
    color: #6a6b6c;
    font-size: 12px;
    line-height: 25px;
    margin-top: 3rem;
  }
  .email-form-wrapper {
    margin-top: 1.9rem;
    .lead-form {
      .ant-form-item {
        margin-bottom: 9px;
      }
      .ant-input {
        padding: 8px 11px;
        border: 1.32px solid #5eb4d8;
        box-shadow: 10px 14px 20px 0px #0000000a;
      }
      .ant-form-item-explain-error {
        font-size: 13px;
        color: red;
      }
    }
    .lead-email {
      font-weight: 600;
      margin-bottom: 10px;
      display: block;
      color: #4F4F4F;

    }
    .show-me-rates-btn {
      font-family: "Circular Std Bold";
      @include vnpGredientButton;
      width: max-content;
      padding: 9px 24px;
      border-radius: 5px;
    }
  }
}
