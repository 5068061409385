@import '../../../Scss/_custom.scss';

.spinner-wrapper {
  @include flexCenter;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.276);
}

.hide-spinner{
 display: none;
}
