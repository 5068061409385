@import '../../../Scss//custom';
@import '../../../Scss/shared.scss';

.review-order-container {
  .overview-txt-wrapper {
    @include commonContainer;
  }

  .main-container {
    @include md {
      padding: 1rem 1.25rem;
    }
    .details-wrapper {
      @include customFlex(space-between, flex-start);
      flex-direction: column;
      @include sm{
        flex-direction: row;
      }
      gap: 2.2rem;
      @include md {
        flex-direction: row;
      }
      margin-top: 15px;
      .delivery-details-wrapper,
      .collection-details-wrapper {
        background-color: #f2fbff;
        box-shadow: 0 4px 4px #00000005;
        width: 100%;
        @include sm {
          max-width: 300px;
        }
        @include xl {
          max-width: 400px;
        }
        .notify-form {
          padding: 0 1rem 1rem 1rem;
        }

        .collection-details,
        .delivery-details {
          border-radius: 4px;
          width: 100%;
          padding: 1rem;
          color: #1e293b;
          word-break: break-all;
          line-height: 25px;
          display: flex;
          flex-direction: column;
          @include xl {
            width: 100%px;
          }
          span {
            line-height: 20px;
          }
        }
      }

      .sender-form-wrapper,
      .receiver-form-wrapper {
        width: 100%;

        .ant-form-item {
          margin-bottom: 15px !important;
        }

        input {
          @include vnpPrimaryInput;
          padding: 0.2rem 0.75rem;
          &::placeholder {
            color: #000000a1;
          }
        }
        .ant-form-item-explain-error {
          font-size: 12px;
          color: red;
        }
      }

      .sender-header-container {
        font-weight: 600;
        color: $vnpPrimary;
        font-size: 18px;
      }
      .contact-num-input {
        height: 30px;
        width: 300px;
        width: -webkit-fill-available;
        padding: 0.2rem 0.75rem;
        &::placeholder {
          color: rgba(0, 0, 0, 0.342);
        }
      }
    }
    .table-wrapper {
      overflow-x: scroll;
    }
    .review-table {
      margin-top: 2rem;
      border-collapse: collapse;
      width: 100%;
      thead {
        tr {
          background: #ddf5ff;
          box-shadow: 0 4px 4px #c9c9c924;
          font-weight: 700;
          font-size: 13px;
          text-transform: none;
          letter-spacing: 0.04em;
          line-height: 1.6;
          color: #000;
          th {
            text-align: start;
            padding: 0.5rem;
            text-align: center;
            &:first-child {
              padding-left: 1.5rem;
              text-align: start;
            }
            &:last-child {
              padding-right: 1.5rem;
            }
          }
        }
      }
      tbody {
        tr {
          .package-type{
            text-align: start !important;
          }
          th {
            text-align: center;
            padding: 0.5rem;
            &:first-child {
              padding-left: 1.5rem;
              color: #0876a4;
            }
            &:last-child {
              padding-right: 1.5rem;
              white-space: nowrap;
            }
          }
        }
        .table-bottom {
          th {
            color: #1e293b !important;
            text-align: end;
          }
        }
      }
    }
  }
  .after-placed-order {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    @include md {
      gap: 4rem;
      flex-direction: row;
    }
    .sender,
    .recipient {
      display: flex;
      gap: 4px;
      flex-direction: column;
      font-weight: 600;
      .head-name{
        margin-bottom: 5px;
        color: $vnpPrimary;
      }
      span {
        color: $vnpPrimary;
        font-weight: 600;
        p {
          margin: 0;
          word-break: break-all;
          display: inline-block;
          color: $vnpSecondary;
          font-weight: 600;
        }
      }
    }
  }
  .notification-config-container {
    padding: 0.5rem 1rem;
    @include vnpToggleSwitch;
    .notify-txt {
      color: $vnpPrimary;
      font-weight: 600;
    }
    .notification-header {
      @include customFlex(flex-start, center);
      gap: 1rem;
      margin-bottom: 0.7rem;
    }

    .send-receive-container {
      margin-bottom: 0.7rem;
      width: 100%;
      @include sm{
        width: 300px;
      }
    }
    .inputs-wrapper {
      label {
        color: #1e293be0;
        font-weight: 600;
        display: block;
        margin-bottom: 4px;
      }
    }
    .notify-form {
      display: flex;
      flex-direction: column;
      @include sm {
        gap: 1rem;
        flex-direction: row;
      }

      .ant-form-item {
        margin-bottom: 15px !important;
      }
    }
  }
}
