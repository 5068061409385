@import './../../Scss/shared.scss';
.layout {

  .ant-layout-header,
  .ant-layout-footer {
    color: #000;
     background-color: $blobstationWhite;
  }

  .logo {
    float: left;
    width: 200px;
    height: 50px;
    margin: 10px 24px;
  }

  .site-layout-content {
    padding: 20px;
     background: white;
    .site-layout-content-box {
      min-height: 400px;
      padding: 0px;
      background: #fff;
    }
  }

  .footer {
    text-align: center;
  }
}