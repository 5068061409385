@import 'Scss/application.scss';
@import 'Scss/shared.scss';

body{
    margin: 0;
    padding: 0;
    font-family: $font-family;
}

::-webkit-scrollbar{
    width:6px;
    height:6px;
   background-color: transparent;
}
::-webkit-scrollbar-track{
    width:6px;
    height:6px;
   background-color: transparent;
}
::-webkit-scrollbar-thumb{
    width:6px;
    height:6px;
    border-radius: 4px;
   background-color: rgb(208, 202, 202);
}