@import '../../../Scss/custom';

.logout-modal .ant-modal-content {
  border-radius: 4px;
}

.navbar {
  width: 100%;
  height: 60px;
  box-shadow: inset 0 -1px 0 0 rgba(98, 105, 118, 0.16);
  background-color: #fff;
  padding: 0rem 0.6rem;
  z-index: 99;
  
  @include sm { padding: 0rem 1.5rem; }
  @include lg{ padding: 0rem 3rem; }
  @include customFlex(center, center);


//  .menu-svg{
//     width: 35px;
//     margin-right: 2rem;
//     display: block;
//     cursor: pointer;

//     @include md{ display: none }
//  }

  .nav-links-wrapper {
    @include customFlex(space-between, center);
    .nav-logo {
      width: 80px;
      // margin-right: 3.5rem;
      cursor: pointer;
    }

    .nav-links {

        display: none;
        @include md{ @include customFlex(space-between, center); }
        gap: 5px;
    }
  }
  // .nav-logout-btn {
  //   cursor: pointer;
  //   @include vnpVipBtn;
  //   .logout-text-svg {
  //       @include customFlex(space-between, center);
  //       margin: 5px 0px;
  //       font-size: 13px;
  //       gap: 4px;
  //     img {
  //       width: 20px;
  //     }
  //   }
  // }
}


@media print{
  .navbar{
    visibility: hidden;
    display: none;
  }
}