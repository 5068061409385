@import '../../../../Scss//custom';

@mixin formLabels {
  display: inline-block;
  border-top-left-radius: 10px;
  padding: 7px 15px;
  color: white;
  width: 100%;
  border-top-right-radius: 10px;
  @include md {
    width: auto;
    padding: 12px 15px;
    border-top-right-radius: 0px;
  }

  h3 {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    // width: 50px;
    line-height: 20px;
    @include md {
      width: 50px;
    }
    // padding: 5px 15px;

    @include xxl {
      width: auto;
    }
  }
}

.location-container{
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.pickup-form {
  width: 100%;
  @include md {
    width: 49%;
    border: 1px solid #9dc7d9;
  }
  height: 100%;
  border-radius: 14px;
  background-color: #fff;

  header {
    @include customFlex(space-between, flex-start);
    flex-direction: column;
    @include md {
      @include customFlex(space-between, center);
      flex-direction: row;
    }
    border-bottom: 1px solid #62697629;
    .pickup-form-label {
      @include formLabels;
      background: #0876a4 !important;
    }
    .delivery-form-label {
      @include formLabels;
      background: $vnpSecondary !important;
    }
    .add-addr-btn {
      @include customFlex(space-between, center);
      gap: 5px;
      color: #8a8a8a;
      font-size: 14px;
      font-weight: 400;
      padding: 0 1rem;
      cursor: pointer;
    }
  }
  .search-address-wrapper {
    font-weight: 500;
    margin-bottom: 0.5rem;
    padding: 1rem;
    font-size: 0.875rem;
    color: #1e293b;
    p {
      margin: 7px 0px;
    }

    .address-select {
      height: 40px;
      box-shadow: 0 1px 3px #0003, 0 1px 1px #00000024, 0 2px 1px -1px #0000001f;
      .ant-select-selector {
        height: 100% !important;
        border: none;
        box-shadow: none;
        box-shadow: none !important;

        input {
          margin-top: 5px;
        }
        .ant-select-selection-item {
          margin-top: 5px;
        }
        .ant-select-selection-placeholder {
          margin-top: 5px;
        }
      }

       .ant-input-clear-icon{
       font-size: 15px;
      }
    }
    .ant-select-arrow {
      color: rgba(0, 0, 0, 0);
    }
  }

  .fav-location {
    padding: 0 1rem;
    .fav-location-label {
      @include customFlex(space-between, center);
      gap: 5px;
      width: fit-content;
      font-weight: 700;
    }
    .fav-list {
      @include customFlex(flex-start, flex-start);
      padding: 10px 0 8px 0;
      gap: 9px;
      overflow-x: scroll;
      .card {
        display: inline-block;
        color: #1e293b;
        background: rgba(179, 208, 221, 0.2);
        border: 1px solid #5eb4d8;
        border-radius: 7px;
        font-weight: 500;
        min-width: 140px;
        padding: 0.4375rem 1rem;
        font-size: 11px;
        line-height: 1.4285714286;
        text-align: center;
        cursor: pointer;
        @include md {
          font-size: 0.875rem;
        }
      }
    }
  }
  .form-wrapper {
    padding: 1rem 1rem 0 1rem;
    @include md {
      padding: 1rem;
    }
    .ant-input[disabled] {
      color: #626976;
      background-color: #fafbfc;
      opacity: 1;
    }

    .form-line {
      flex-direction: column;
      @include lg {
        @include customFlex(space-between, center);
        flex-direction: row;
        gap: 14px;
      }
      .ant-form-item {
        width: 100%;
      }
      .masked-input {
        @include maskedInput;
        padding: 0.54rem 0.75rem !important;
        width: 100% !important;
      }
      .inputs {
        @include vnpPrimaryInput;
        padding: 0.35rem 0.75rem;
      }
    }

    .form-line-footer {
      @include customFlex(space-between, center);
      flex-direction: column;
      gap: 0px;
      @include lg {
        flex-direction: row;
        gap: 14px;
      }
      .ant-form-item {
        width: 100%;
      }
      .masked-input {
        @include maskedInput;
        padding: 0.54rem 0.75rem !important;
        width: 100%;
      }
    }

    .footer-btns {
      @include customFlex(space-around, center);
      gap: 12px;
      flex-direction: column;
      @media screen and (min-width: 400px) {
        flex-direction: row;
      }
      .btn {
        @include vnpPrimaryBtn;
        width: 100%;
      }
    }

    .ant-form-item {
      margin-bottom: 14px;
    }
    .popup-inputs {
      @include vnpPrimaryInput;
      padding: 0.35rem 0.75rem;
    }

    .address-popup-form-btn {
      box-sizing: border-box;
      @include vnpSecondryGreenBtn;
    }
  }
}

.ant-select-dropdown {
  position: absolute;
  background: white;
  height: auto;
  box-shadow: 0 2px 5px #00000040;
  border-radius: 1px;
}

.ant-select-item-option {
  border-bottom: 1px solid rgba(0, 0, 0, 0.136);
  border-radius: 0px !important;
}

.address-details-form{
  .ant-form-item-control {
    flex: 1;
  }
  .ant-row {
    flex-direction: column;
  }
  .ant-form-item-label{
   width: max-content;
  }
  .ant-form-item .ant-form-item-label >label::after {
    display: none;
  }
}